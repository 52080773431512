import Vue from "vue";
import Vuex from "vuex";
import http from "@/utils/request";
import {
	isEmptyObject,
	assign,
	shouldUpdateURL,
	isInteger,
} from "@/utils/common";
import Loading from "@/components/common/loading";
import Toast from "@/components/common/toast";
import {
	storeChapterInfo,
	updateSlideBlockPos,
	saveCacheData,
} from "@/utils/chapter";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// 当前小说的所有章节内容，最新用这个
		allChapterData: {},
		// 目录弹窗的状态
		showContents: false,
		// 工具栏的状态
		showToolbar: false,
		// 缓存的章节（已经发起过请求的书，之后跳转就无须发起请求，以章节id为属性名）
		cacheChapter: {},
		// 章节信息
		chapterInfo: {
			novelId: Number(location.pathname.split("/")[1]),
			chapterId: Number(location.pathname.split("/")[2]),
			contentList: [],
			chapterTitle: "",
			novelTitle: "",
			startChapterId: 0,
			endChapterId: 0,
		},
		// 小说信息
		novelInfo: {
			// 小说标题
			novelTitle: "",
			// 章节列表
			chapterList: [],
		},
		// 当前页面状态信息
		currentInfo: {
			// 页号
			pageNum: 1,
			// 背景色号
			bgColorNum: JSON.parse(localStorage.getItem("bgColor") || "1"),
			// 是否是日间模式
			isDayMode: JSON.parse(localStorage.getItem("dayMode") || "true"),
			// 字体大小
			fontSize: JSON.parse(localStorage.getItem("textFontSize") || "4"),
			// 文本间距 1:大 2：中 3：小 4：标准
			lineHeight: JSON.parse(
				localStorage.getItem("textLineHeight") || "4"
			),
			// 左手模式
			leftMode: JSON.parse(localStorage.getItem("leftMode") || "false"),
			// 上下翻页模式
			// scrollTurnPage: JSON.parse(
			// 	localStorage.getItem("scrollTurnPage") || "false"
			// ),
			scrollTurnPage: true,
			// 工具栏菜单的状态
			showMenu: false,
			// 当前激活的菜单
			activeMenu: "",
			// 当前章节总页数
			totalPageNum: 0,
		},
		// 切换章节slider信息
		chapterSliderInfo: {
			// 当前进度
			progress: 0,
			// 显示进度窗口
			showProgressWindow: false,
		},
		// 翻页容器的信息
		containerInfo: {
			// 容器当前的位置translateX
			currentPos: 0,
			// 一页的宽度
			pageWidth: 0,
			// 文本容器的总宽度
			containerWidth: 0,
			// 文本容器dom元素
			$page: null,
			// 翻页模式，1表示横向翻页，0表示竖向翻页
			turnPageMode: 1,
			// 是否可以滑动
			canSlide: true,
		},
		// 上下翻页容器的信息
		verticalContainerInfo: {
			// 上下翻页组件的页面
			$page: null,
			// 章节列表的容器
			$chapterContainer: null,
			// 章节列表
			chapterList: [],
			// 是否允许下滑加载数据
			shouldScrollLoad: true,
		},
		// 停留页面期间看的文章id列表
		viewedChapterIdList: [],
		// 是否阅读超过8章
		viewedMoreThan8: false,
		// 是否停留超过10分钟
		isStayMoreThan10: false,
		// 是否点击了afc广告
		isClickAfc: false,
	},
	mutations: {
		updateContentsStatus(state) {
			state.showContents = !state.showContents;
		},

		updateViewedChapterIdList(state, payload) {
			state.viewedChapterIdList = payload.idList;
		},

		updateViewedMoreThan8(state, payload) {
			state.viewedMoreThan8 = payload.viewedMoreThan8;
		},

		updateIsStayMoreThan10(state, payload) {
			state.isStayMoreThan10 = payload.isStayMoreThan10;
		},

		updateIsClickAfc(state, payload) {
			state.isClickAfc = payload.isClickAfc;
		},

		updateToolBarStatus(state) {
			state.showToolbar = !state.showToolbar;
		},

		// 更新容器相关的信息
		updateContainerInfo(state, payload) {
			state.containerInfo = assign(state.containerInfo, payload);
		},

		// 更新当前页面的信息
		updateCurrentInfo(state, payload) {
			state.currentInfo = assign(state.currentInfo, payload);
		},

		// 更新章节slider新的
		updateChapterSliderInfo(state, payload) {
			state.chapterSliderInfo = assign(state.chapterSliderInfo, payload);
		},

		// 更新章节相关信息
		updateChapterInfo(state, payload) {
			state.chapterInfo = assign(state.chapterInfo, payload);
		},

		// 更新小说相关信息
		updateNovelInfo(state, payload) {
			state.novelInfo = assign(state.novelInfo, payload);
		},

		// 更新小说相关信息
		updateAllChapterInfo(state, payload) {
			state.allChapterData = assign(state.allChapterData, payload);
		},

		// 更新缓存章节信息
		updateCacheChapterInfo(state, payload) {
			state.cacheChapter = assign(state.cacheChapter, payload);
		},

		// 更新上下翻页容器信息
		updateVerticalContainerInfo(state, payload) {
			state.verticalContainerInfo = assign(
				state.verticalContainerInfo,
				payload
			);
		},
	},
	actions: {
		// 获取章节信息
		getChapterInfo({ dispatch, state, commit }, params) {
			const { scrollTurnPage } = state.currentInfo;
			const { startChapterId, endChapterId } = state.allChapterData;
			const { chapterId } = params;

			// 判断边界，兜底
			if (startChapterId && chapterId < startChapterId) {
				return Toast("已经是第一章");
			}

			if (endChapterId && chapterId > endChapterId) {
				return Toast("当前是最后一章");
			}

			// 如果没有看过这一章节则添加到list
			if (!state.viewedChapterIdList.includes(Number(chapterId))) {
				const newIdList = [
					...state.viewedChapterIdList,
					Number(chapterId),
				];

				commit("updateViewedChapterIdList", {
					idList: newIdList,
				});

				// 阅读超过8篇进行标记
				if (newIdList.length >= 8) {
					// 阅读超过8篇文章
					if (!state.viewedMoreThan8) {
						ttq.track("CompleteRegistration");
						console.log("CompleteRegistration: view more than 8");
					}

					if (state.isClickAfc) {
						// 阅读超过8篇文章并且点击过afc广告
						ttq.track("InitiateCheckout");
						console.log(
							"InitiateCheckout: view more than 8 and click afc"
						);

						// 并且停留超过10分钟
						if (state.isStayMoreThan10) {
							ttq.track("CompletePayment");
							console.log(
								"CompletePayment: view more than 8 and click afc and stay more than 10 minute"
							);
						}
					}

					commit("updateViewedMoreThan8", {
						viewedMoreThan8: true,
					});
				}
			}

			// 判断翻页方式
			if (scrollTurnPage) {
				dispatch("getVerticalChapterInfo", params);
			} else {
				dispatch("getHorizontalChapterInfo", params);
			}
		},

		// 上下翻页容器获取章节数据
		getVerticalChapterInfo({ state, commit, dispatch }, params) {
			/**
			 * scrollLoad表示是否是通过滚动的形式加载数据，因为还可以点击目录加载数据
			 */
			const {
				novelId,
				chapterId,
				replacePath = true,
				showLoading = true,
				scrollLoad = false,
				pullLoad = false,
				callback,
			} = params;
			let {
				$page,
				$chapterContainer,
				chapterList,
			} = state.verticalContainerInfo;
			let verticalChapterList = [...chapterList];

			showLoading && Loading.show();

			new Promise((resolve) => {
				const { chapterList } = state.allChapterData;
				// 当前要加载的章节信息
				const newChapterInfo = chapterList[chapterId - 1];

				if (!isEmptyObject(state.allChapterData)) {
					commit("updateChapterInfo", { chapterId });

					if (scrollLoad) {
						verticalChapterList.push(newChapterInfo);
					} else if (pullLoad) {
						verticalChapterList.unshift(newChapterInfo);
					} else {
						verticalChapterList = [newChapterInfo];
					}

					commit("updateVerticalContainerInfo", {
						chapterList: verticalChapterList,
					});
					// 如果翻页模式是上下翻页，不执行then里面的方法
					resolve();
				} else {
					Toast("内容不存在");
					reject();
				}
			})
				.then(() => {
					let { startChapterId, endChapterId } = state.chapterInfo;

					// 执行回调
					callback && callback();

					// 更新url参数
					shouldUpdateURL({
						replacePath,
						novelId,
						chapterId,
					});

					// 更新章节slider滑块的位置
					updateSlideBlockPos({
						commit,
						chapterId,
						endChapterId,
						startChapterId,
					});

					// 更新容器的位置
					if (!scrollLoad && !pullLoad) {
						$page.scrollTo(0, 0);
					} else if (pullLoad) {
						// 获取第一个章节信息盒子的高度，然后让页面滚到到这个高度
						const firstChapterBoxHeight = document.querySelector(
							".reader-page__viewport-vertical__chapter"
						).clientHeight;
						$page.scrollTo(0, firstChapterBoxHeight);
					}

					// 当页面内容没有占满屏幕时，自动在加载下一章内容
					if ($chapterContainer.clientHeight <= $page.clientHeight) {
						dispatch("getChapterInfo", {
							novelId,
							chapterId: Number(chapterId) + 1,
							scrollLoad: true,
						});
					}

					Loading.close();
				})
				.catch((err) => {
					console.log(
						err,
						"Happened in getVerticalChapterInfo Promise catch function..."
					);
				});
		},

		// 水平翻页容器获取章节数据
		getHorizontalChapterInfo({ state, commit }, params) {
			// backEndPage表示加载完数据后是否回到章节最后一页, replacePath表示是否更新router的path
			const {
				novelId,
				chapterId,
				backEndPage = false,
				replacePath = true,
			} = params;

			// 如果id没有变，return
			if (chapterId === state.chapterInfo.chapterId) {
				return;
			}

			// 加载数据的时候禁止页面滑动翻页
			commit("updateContainerInfo", { canSlide: false });

			new Promise((resolve, reject) => {
				// 开启loading
				Loading.show();

				const {
					chapterList,
					novelId,
					novelTitle,
					startChapterId,
					endChapterId,
				} = state.allChapterData;

				let result = {};
				result.novelId = Number(novelId);
				result.novelTitle = novelTitle;
				result.startChapterId = startChapterId;
				result.endChapterId = endChapterId;
				result = Object.assign({}, result, chapterList[chapterId - 1]);

				if (!isEmptyObject(state.allChapterData)) {
					commit("updateChapterInfo", result);
					// 如果翻页模式是上下翻页，不执行then里面的方法
					resolve();
				} else {
					// TODO: 提示数据不存在
					reject();
				}
			})
				.then(() => {
					// 更新url参数
					shouldUpdateURL({
						replacePath,
						novelId,
						chapterId,
					});

					// 更新容器和当前设置信息
					const { pageWidth, $page } = state.containerInfo;
					const { startChapterId, endChapterId } = state.chapterInfo;
					const containerWidth = $page.scrollWidth;
					const calculatePageNum = containerWidth / pageWidth;
					// 判断页数的计算结果是不是整数，如果不是，向下取整，兼容手机端
					const totalPageNum = isInteger(calculatePageNum)
						? calculatePageNum
						: Math.floor(calculatePageNum);

					// 更新章节总页数和当前页数
					commit("updateCurrentInfo", {
						totalPageNum,
						pageNum: backEndPage ? totalPageNum : 1,
					});

					// 更新章节slider滑块的位置
					updateSlideBlockPos({
						commit,
						chapterId,
						endChapterId,
						startChapterId,
					});

					// 更新容器当前位置
					commit("updateContainerInfo", {
						currentPos: backEndPage
							? pageWidth - containerWidth
							: 0,
						containerWidth,
						// 数据加载结束后，允许滑动翻页
						canSlide: true,
					});

					// 容器复位
					$page.style.transitionDuration = "0s";
					$page.style.transform = `translateX(${
						backEndPage ? -containerWidth + pageWidth : 0
					}px)`;

					// 关闭loading
					Loading.close();
				})
				.catch((err) => {
					console.log(
						err,
						"Happened in getChapterInfo Promise catch function..."
					);
				});
		},

		// 获取章节列表数据
		getChapterListInfo({ commit, state }, params) {
			const { novelId } = state.chapterInfo;

			http.get(`/novel/${novelId}.json`)
				.then((res) => {
					const chapterListData = {};

					chapterListData.chapterList = res?.data.chapterList.map(
						(item) => {
							return {
								chapterTitle: item.chapterTitle,
								chapterId: item.chapterId,
							};
						}
					);
					chapterListData.novelId = Number(res?.data?.novelId);
					chapterListData.novelTitle = res?.data.novelTitle;

					commit("updateNovelInfo", chapterListData);
					commit("updateAllChapterInfo", res?.data);
					params?.onLoad?.();
				})
				.catch((err) => {
					Toast("内容不存在");
					console.log(err, "Happened in getChapterInfo function");
				});
		},
	},
	modules: {},
});
