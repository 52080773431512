<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

  mounted() {
    this.track()

    setTimeout(() => {
      this.$store.commit('updateIsStayMoreThan10', {
        isStayMoreThan10: true
      })
      ttq.track('AddToWishlist')
      console.log('AddToWishlist: stay 10 minutes')

      if (this.$store.state.isClickAfc) {
        ttq.track('AddPaymentInfo')
        console.log('AddPaymentInfo')

        if (this.$store.state.viewedMoreThan8) {
          // 阅读超过8篇文章并且点击过afc广告
          ttq.track("CompletePayment");
          console.log(
            "CompletePayment: view more than 8 and click afc and stay more than 10 minute"
          );
        }
      }
    }, 10 * 60 * 1000);
  },

  methods: {
    track() {
      window.focus();
      window.addEventListener('blur', () => {
        const r = document.activeElement;
        console.log('blur element', r);
        if (r && r.tagName.toLowerCase() === 'iframe') {
          // 当前iframe广告的爷爷节点
          const parentNode = r?.parentNode?.parentNode;
          // 当前是不是插屏穿插广告
          const isScreenAd =
            parentNode?.getAttribute('data-slotcar-interstitial') ||
            parentNode?.getAttribute('data-vignette-loaded');

          // 点击afc广告事件
          ttq.track('AddToCart')
          this.$store.commit("updateIsClickAfc", {
            isClickAfc: true
          })

          if (this.$store.state.isStayMoreThan10) {
            // 阅读超过8篇文章并且点击过afc广告
            ttq.track("AddPaymentInfo");
            console.log(
              "AddPaymentInfo"
            );
          }

          if (this.$store.state.viewedMoreThan8) {
            // 阅读超过8篇文章并且点击过afc广告
            ttq.track("InitiateCheckout");
            console.log(
              "InitiateCheckout: view more than 8 and click afc"
            );

            // 并且停留超过10分钟
            if (this.$store.state.isStayMoreThan10) {
              ttq.track("CompletePayment");
              console.log(
                "CompletePayment: view more than 8 and click afc and stay more than 10 minute"
              );
            }
          }
        }
        setTimeout(() => {
          window.focus();
        }, 0);
      })
    }
  }
}
</script>

<style>
@import url('./assets/css/common.css')
</style>
